.react-chatbot-kit-chat-header {
    background-color: rgb(38, 47, 61);
    color: whitesmoke;
    display: flex;
    /* justify-content: space-between; */
}

.react-chatbot-kit-chat-header-row{
    width: 100%;
}

.react-chatbot-kit-chat-header-title{
    margin-top: 14px;
}

.react-chatbot-kit-chat-container {
    /* width: 80vw; */
    width: auto;
    /* max-width: 1000px; */
    height: 85vh;
    /* max-height: 1000px; */
}

.react-chatbot-kit-chat-message-container {
    height: 80vh;
    /* max-height: 925px; */
    overflow: auto;
    background-image: linear-gradient(to bottom left, rgb(130, 194, 184), whitesmoke)
}

.react-chatbot-kit-chat-bot-message {
    margin-left: 5px;
    width: auto;
    max-width: 75%;
}

.react-chatbot-kit-user-chat-message {
    margin-right: 5px;
    width: auto;
    max-width: 75%;
}

.react-chatbot-kit-user-chat-message-arrow {
    color: white;
}

.react-chatbot-kit-chat-input {
    background-color: rgb(163, 196, 191);
}

.react-chatbot-kit-chat-input-container {
    position: relative;
}

.react-chatbot-kit-chat-bot-avatar-icon {
  background-image: url('https://oxygen.patten.edu/static/media/oxygen.4b7d8b3be1587a5c16551c715b70a877.svg');
  background-size: contain;
  background-repeat: no-repeat; 
  background-position: center; 
  width: 55px; 
  height: 55px; 
}


.left-side-bar{
    min-width: 250px;
    max-width: 15vw;
    margin: 2vh 1vw 2vh 2vw;
    height: 96vh;
}

.right-side-container{
    width: calc(100vw - 250px);
    margin: 2vh 2vw 2vh 1vw;
}

.session-list-div {
    overflow: auto;
    height: 75vh;
}

.session-container {
    margin: 0 0 2vh 0;
}

.btn btn-primary {
    background-color: rgb(38, 47, 61);
}

.ChatBot {
    display: flex;
    align-items: stretch;
    justify-content: center;
}

.session-title {
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-list-item {
    cursor: pointer !important;
    transition: background-color 0.3s ease !important; /* Smooth transition */
}

.custom-list-item:hover {
    background-color: #f0f0f0 !important;
}

.custom-list-item.active {
    /* Your styles for active item */
    background-color: rgb(38, 47, 61) !important;
}

.delete-session-button {
    margin: 5px 15px;
}

.delete-session-button:hover {
    background-color: rgb(167, 55, 27) !important;
    color: rgb(230, 230, 230) !important;
    margin: 5px 15px;
}

.save-chat-button{
    background-color: #2898ec !important;
    margin: 5px 15px;
}

.loading-spinner {
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.long-list-note {
    background-color: rgb(38, 47, 61) !important; 
    color: rgb(230, 230, 230) !important;
}

.spinner-grow {
    height: 0.5rem !important;
    width: 0.5rem !important;
    margin: 0.15rem;
}

.loading-span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%
}
